import React, { useState } from "react";
import { Menu } from "react-feather";
import Scrollspy from "react-scrollspy";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import "../../App.css";
import logo from "../../assets/main-logo.png";
import SearchBar from "./searchBar";

const WrapperNavBar = styled.div`
  nav a,
  nav > div > a {
    text-decoration: none;
    color: #583703;
    font: normal normal bold 18px/33px Noto Sans;
  }
  .logo {
    font: normal normal 900 30px/46px Noto Sans;
    color: #ff8744;
  }
  nav li a:hover {
    color: #ff8744;
    border-bottom: 3px solid #ff8744;
  }
  .activeClass a {
    color: #ff8744;
    border-bottom: 3px solid #ff8744;
  }
  .navButton a:hover {
    border-bottom: none !important;
  }
  .navButton a {
    font: normal normal bold 18px/26px Noto Sans;
    /* background: var(--unnamed-color-ff8744) 0% 0% no-repeat padding-box; */
    background: #ff8744 0% 0% no-repeat padding-box;
    border-radius: 30px;
    padding: 0.5rem 2rem;
    color: #fff !important;
    border: none;
    width: 200px;
    height: 40px;
  }
  .navBarSlide-1 {
    width: 80%;
    height: 100vh;
    min-height: 500px;
    position: fixed;
    right: -100%;
    top: 0px;
    background: #fff;
    transform: translateX(1110px);
    transition: 1s;
    z-index: 9999;
  }
  .navSize {
    position: sticky;
    top: 0px;
    z-index: 99;
    padding: 0.5rem 0px;
    background-color: #fff;
  }
  .navBarSlide-2 {
    width: 80%;
    height: 100vh;
    min-height: 500px;
    position: fixed;
    right: 0px;
    top: 0px;
    background: #fff;
    transform: translateX(0px);
    transition: 1s;
    z-index: 9999;
  }
  .navBarIcon {
    display: none;
  }
  .navBarIcon button {
    background: none;
    border: none;
  }
  .btn:active {
    background-color: #ff8744;
  }
  .btn:focus-visible {
    box-shadow: none;
  }
  .flexNav {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .ProjectLogo {
    color: #ff8744;
    width: 250px;
    margin-left: 1rem;
    font-size: 21px;
    font-weight: 900;
    margin-top: 0.5rem;
  }
  ul {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1199px) {
    nav a {
      font: normal normal bold 18px/33px Noto Sans;
    }
    .navButton {
      font: normal normal bold 18px/26px Noto Sans;
      width: 180px;
      /* height: 40px; */
    }
    .logo {
      font: normal normal 900 26px/46px Noto Sans;
    }
  }
  @media screen and (max-width: 991px) and (min-width: 768px) {
    nav a {
      font: normal normal bold 15px/33px Noto Sans;
    }
    .navButton {
      font: normal normal bold 15px/26px Noto Sans;
      width: 130px;
      height: 40px;
    }
    .logo {
      font: normal normal 900 18px/46px Noto Sans;
      color: #ff8744;
    }
    .navButton a {
      font: normal normal bold 15px/45px Noto Sans;
      padding: 0.3rem 1rem;
    }
  }

  @media screen and (max-width: 768px) and (min-width: 0px) {
    .navBar {
      display: none !important;
    }

    .navBarIcon {
      display: block;
      cursor: pointer;
    }
    nav a {
      font: normal normal bold 15px/25px Noto Sans;
      color: #fff;
    }
  }
`;
export default function Nav() {
  const [IsOpen, setIsOpen] = useState(false);
  return (
    <WrapperNavBar>
      <div className="container-fluid navSize position-fixed">
        <div className="container py-2">
          <div className="row">
            <div className="col-lg-12">
              <Row className="justify-content-between align-items-center">
                <Col xs={2} className="order-md-1 order-2 text-end">
                  <div className="logo navBar">
                    <img
                      src={logo}
                      width="100%"
                      height="100%"
                      alt="website Logo"
                    />
                  </div>
                  <div className="navBarIcon ">
                    <button
                      onClick={() => setIsOpen(!IsOpen)}
                      type="button"
                      className="p-0"
                    >
                      <Menu color="#583703" />
                    </button>
                  </div>
                </Col>
                <Col lg={4} xs={10} className="order-1 order-lg-2">
                  <SearchBar />
                </Col>
                <Col md={12} lg="6" className="order-3">
                  <nav>
                    <Scrollspy
                      items={["home", "about-us", "registration"]}
                      currentClassName="activeClass"
                      // offset={-180}
                      className="text-capitalize nav navBar  align-items-center d-flex justify-content-around "
                    >
                      <li>
                        <a href="/#home">Home</a>
                      </li>
                      <li>
                        <a href="/#about-us">About</a>
                      </li>
                      <li>
                        <a href="/#productservices">Product & Services</a>
                      </li>
                      <li>
                        <a href="/#pricing">Pricing</a>
                      </li>
                      <li>
                        <a href="/#registration">Register</a>
                      </li>
                      <li className="navButton">
                        <a href="/#contact-us">Contact Us</a>
                      </li>
                    </Scrollspy>
                  </nav>

                  <div
                    className={`${IsOpen ? "navBarSlide-2" : "navBarSlide-1"}`}
                  >
                    <div
                      style={{
                        position: "fixed",
                        right: "10px",
                        top: "10px",
                        fontWeight: "600",
                      }}
                      onClick={() => setIsOpen(!IsOpen)}
                    >
                      <svg width="25" height="25">
                        <path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" />
                      </svg>
                    </div>
                    <div className="ProjectLogo">
                      {" "}
                      <img
                        src={logo}
                        width="100%"
                        height="100%"
                        alt="Website Logo"
                      />
                    </div>
                    <nav className="ms-3 mt-3">
                      {[
                        ["Home", "/#home"],
                        ["about", "/#about-us"],
                        ["Product & Services", "/#productservices"],
                        ["Pricing", "/#pricing"],
                        ["Register", "/#registration"],
                        ["Contact Us", "/#contact-us"],
                      ].map(([title, url], index) => (
                        <div onClick={() => setIsOpen(!IsOpen)}>
                          <a
                            href={url}
                            onClick={() => {
                              if (title === "Log In") {
                                Swal.fire({
                                  icon: "info",
                                  title: "Oops...",
                                  text: "Log in page is under development..!",
                                });
                              }
                            }}
                            className="list-group my-2 "
                            key={index}
                          >
                            {title}
                          </a>
                        </div>
                      ))}
                    </nav>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </WrapperNavBar>
  );
}
